import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'
import Login from './screens/Login'
import DashboardLayout from './screens/DashboardLayout.js';
import Register from './screens/Register.js';
import LockScreen from './screens/LockScreen.js';
import PrivateRoute from './components/PrivateRoute.js';

import 'admin-lte/dist/css/adminlte.min.css';
import 'admin-lte/dist/js/adminlte.min.js';

function App() {
  const isAuthenticated = () => {
    // Replace with actual authentication check
    //return localStorage.getItem('authToken');
    return false;
  };
  return (
    <Router>
      <Routes>

        <Route path='/login' element= { <Login/> } />
        <Route path='/register' element= { <Register/> } />
        <Route path='/lock' element= { <LockScreen/> } />

        <Route path="/dashboard" element = {
            <PrivateRoute isAuthenticated={isAuthenticated()}>
              <DashboardLayout />
            </PrivateRoute>
          }
        />

        <Route path="*" element={isAuthenticated() ? <Navigate to="/dashboard" /> : <Navigate to="/login" />} />
      </Routes>
    </Router>
  );
}

export default App;
