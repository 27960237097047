import React, { Component } from 'react'

const Sidebar = () => {
    return (
        
            <aside className="app-sidebar bg-body-secondary shadow" data-bs-theme="dark"> {/*begin::Sidebar Brand*/}
            <div className="sidebar-brand">
                {/*begin::Brand Link*/}
                <a href="./index.html" className="brand-link">
                    {/*begin::Brand Image*/}
                    <img src="../../dist/assets/img/AdminLTELogo.png" alt="Logo" className="brand-image opacity-75 shadow" />
                    {/*end::Brand Image*/}
                    {/*begin::Brand Text*/} <span className="brand-text fw-light">Quiz Admin</span> 
                    {/*end::Brand Text*/}
                </a> {/*end::Brand Link*/} </div> {/*end::Sidebar Brand*/} {/*begin::Sidebar Wrapper*/}
            <div className="sidebar-wrapper">
                <nav className="mt-2"> {/*begin::Sidebar Menu*/}
                <ul className="nav sidebar-menu flex-column" data-lte-toggle="treeview" role="menu" data-accordion="false">
                    
                    <li className="nav-item"> <a href="/" className="nav-link"> <i className="nav-icon bi bi-circle" />
                        <p>Dashboard</p>
                    </a> </li>
                    <li className="nav-item"> <a href="/board" className="nav-link"> <i className="nav-icon bi bi-circle" />
                        <p>Boards / University</p>
                    </a> </li>
                    <li className="nav-item"> <a href="/board" className="nav-link"> <i className="nav-icon bi bi-circle" />
                        <p>Institutes</p>
                    </a> </li>
                    <li className="nav-item"> <a href="/board" className="nav-link"> <i className="nav-icon bi bi-circle" />
                        <p>Classes</p>
                    </a> </li>
                    <li className="nav-item"> <a href="/board" className="nav-link"> <i className="nav-icon bi bi-circle" />
                        <p>Subjects</p>
                    </a> </li>
                    <li className="nav-item"> <a href="/board" className="nav-link"> <i className="nav-icon bi bi-circle" />
                        <p>Exam Pattern</p>
                    </a> </li>

                    <li className="nav-item"> <a href="/board" className="nav-link"> <i className="nav-icon bi bi-circle" />
                        <p>Question Papers</p>
                    </a> </li>
                    
                    <li className="nav-item"> <a href="/board" className="nav-link"> <i className="nav-icon bi bi-circle" />
                        <p>Roles</p>
                    </a> </li>
                    <li className="nav-item"> <a href="/board" className="nav-link"> <i className="nav-icon bi bi-circle" />
                        <p>Users</p>
                    </a> </li>
                    <li className="nav-item"> <a href="/board" className="nav-link"> <i className="nav-icon bi bi-circle" />
                        <p>Logins</p>
                    </a> </li>
                </ul> {/*end::Sidebar Menu*/}
                </nav>
            </div> {/*end::Sidebar Wrapper*/}
            </aside>
    )
}
export default Sidebar;