import React, { useEffect } from 'react'


import HeaderBar from '../components/HeaderBar'
import MainComponents from '../components/MainComponents';
import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';

const DashboardLayout = () => {
  useEffect(() => {
    // Change body class when component is mounted
    document.body.classList.add('layout-fixed');
    document.body.classList.add('sidebar-expand-lg');
    document.body.classList.add('bg-body-tertiary');
    document.body.classList.add('app-loaded');
    document.body.classList.add('sidebar-open');


    // Clean up the class when component is unmounted
    return () => {
      document.body.classList.remove('dashboard-body-class');
    };
  }, []);
    return (
      <div className='app-wrapper'>
        <HeaderBar/>
        <Sidebar/>
        <MainComponents/>
        <Footer/>
        <div className="sidebar-overlay"></div>
      </div>
    )
}

export default DashboardLayout;