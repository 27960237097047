import React, { Component } from 'react'

const MainComponents = () => {
    return (
        
            <main className="app-main"> {/*begin::App Content Header*/}
            <div className="app-content-header"> {/*begin::Container*/}
                <div className="container-fluid"> {/*begin::Row*/}
                <div className="row">
                    <div className="col-sm-6">
                    <h3 className="mb-0">Dashboard</h3>
                    </div>
                    <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-end">
                        <li className="breadcrumb-item"><a href="#">Home</a></li>
                        <li className="breadcrumb-item active" aria-current="page">
                        Dashboard
                        </li>
                    </ol>
                    </div>
                </div> {/*end::Row*/}
                </div> {/*end::Container*/}
            </div>
            <div className="app-content"> {/*begin::Container*/}
                <div className="container-fluid"> {/* Info boxes */}
                <div className="row">
                    <div className="col-12 col-sm-6 col-md-3">
                    <div className="info-box"> <span className="info-box-icon text-bg-primary shadow-sm"> <i className="bi bi-gear-fill" /> </span>
                        <div className="info-box-content"> <span className="info-box-text">Schools</span> <span className="info-box-number">5</span> </div> {/* /.info-box-content */}
                    </div> {/* /.info-box */}
                    </div> {/* /.col */}
                    <div className="col-12 col-sm-6 col-md-3">
                    <div className="info-box"> <span className="info-box-icon text-bg-danger shadow-sm"> <i className="bi bi-hand-thumbs-up-fill" /> </span>
                        <div className="info-box-content"> <span className="info-box-text">Questions</span> <span className="info-box-number">10</span> </div> {/* /.info-box-content */}
                    </div> {/* /.info-box */}
                    </div> {/* /.col */} {/* fix for small devices only */} {/* <div class="clearfix hidden-md-up"></div> */}
                    <div className="col-12 col-sm-6 col-md-3">
                    <div className="info-box"> <span className="info-box-icon text-bg-success shadow-sm"> <i className="bi bi-cart-fill" /> </span>
                        <div className="info-box-content"> <span className="info-box-text">Question Papers</span> <span className="info-box-number">60</span> </div> {/* /.info-box-content */}
                    </div> {/* /.info-box */}
                    </div> {/* /.col */}
                    <div className="col-12 col-sm-6 col-md-3">
                    <div className="info-box"> <span className="info-box-icon text-bg-warning shadow-sm"> <i className="bi bi-people-fill" /> </span>
                        <div className="info-box-content"> <span className="info-box-text">Users</span> <span className="info-box-number">2,000</span> </div> {/* /.info-box-content */}
                    </div> {/* /.info-box */}
                    </div> {/* /.col */}
                </div> {/* /.row */}
                {/*begin::Row*/}
                </div> {/*end::Container*/}
            </div> {/*end::App Content*/}
            </main>
    )
}

export default MainComponents;