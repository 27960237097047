import React from 'react';
import { APP_NAME, APP_ENV } from '../globals';

const Login = () => {
  return (
    <div className="login-page bg-body-secondary">
      <div className="login-box">
        <div className="login-logo">
          <a href="#"><b>{ APP_NAME }</b>{ APP_ENV }</a>
        </div> {/* /.login-logo */}
        <div className="card">
          <div className="card-body login-card-body">
            <p className="login-box-msg">Sign in to start your session</p>
            <form action="../index3.html" method="post">
              <div className="input-group mb-3">
                <input type="email" className="form-control" placeholder="Email" />
                <div className="input-group-text">
                  <span className="bi bi-envelope" />
                </div>
              </div>
              <div className="input-group mb-3">
                <input type="password" className="form-control" placeholder="Password" />
                <div className="input-group-text">
                  <span className="bi bi-lock-fill" />
                </div>
              </div>
              {/*begin::Row*/}
              <div className="row">
                <div className="col-8">
                  <div className="form-check">
                    <input className="form-check-input" type="checkbox" id="flexCheckDefault" />
                    <label className="form-check-label" htmlFor="flexCheckDefault">
                      Remember Me
                    </label>
                  </div>
                </div>
                {/* /.col */}
                <div className="col-4">
                  <div className="d-grid gap-2">
                    <button type="submit" className="btn btn-primary">Sign In</button>
                  </div>
                </div>
                {/* /.col */}
              </div>
              {/*end::Row*/}
            </form>
            {/*<p className="mb-1"> <a href="forgot-password.html">I forgot my password</a> </p>*/}
            <p className="mb-0">
              <a href="/register" className="text-center">
                Register a new membership
              </a>
            </p>
          </div>
          {/* /.login-card-body */}
        </div>
      </div>
    </div>
  );
};

export default Login;