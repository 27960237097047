import React from 'react';
import { APP_NAME, APP_ENV } from '../globals';

const Register = () => {
    return (
        <div className="register-page bg-body-secondary">
            <div className="register-box">
                <div className="register-logo"> <a href="../index2.html"><b>{ APP_NAME }</b>{ APP_ENV }</a> </div> {/* /.register-logo */}
                <div className="card">
                <div className="card-body register-card-body">
                    <p className="register-box-msg">Register a new membership</p>
                    <form action="../index3.html" method="post">
                    <div className="input-group mb-3"> <input type="text" className="form-control" placeholder="Full Name" />
                        <div className="input-group-text"> <span className="bi bi-person" /> </div>
                    </div>
                    <div className="input-group mb-3"> <input type="email" className="form-control" placeholder="Email" />
                        <div className="input-group-text"> <span className="bi bi-envelope" /> </div>
                    </div>
                    <div className="input-group mb-3"> <input type="password" className="form-control" placeholder="Password" />
                        <div className="input-group-text"> <span className="bi bi-lock-fill" /> </div>
                    </div> {/*begin::Row*/}
                    <div className="row">
                        <div className="col-8">
                        <div className="form-check"> <input className="form-check-input" type="checkbox" defaultValue id="flexCheckDefault" /> <label className="form-check-label" htmlFor="flexCheckDefault">
                            I agree to the <a href="#">terms</a> </label> </div>
                        </div> {/* /.col */}
                        <div className="col-4">
                        <div className="d-grid gap-2"> <button type="submit" className="btn btn-primary">Sign In</button> </div>
                        </div> {/* /.col */}
                    </div> {/*end::Row*/}
                    </form>
                    <p className="mb-0"> <a href="/login" className="text-center">
                        Already registered
                    </a> </p>
                </div> {/* /.register-card-body */}
                </div>
            </div> {/* /.register-box */}
        </div>
    )
}

export default Register;