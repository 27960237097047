import React, { Component } from 'react'
import { Navigate } from 'react-router-dom';

export default class PrivateRoute extends Component {
  render() {
    const { isAuthenticated, children } = this.props;
    
    return (isAuthenticated ? children : <Navigate to="/login" />)
  }
}
