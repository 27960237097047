import React from 'react'

const Footer = () => {
    return (
          
            <footer className="app-footer"> {/*begin::To the end*/}
              <div className="float-end d-none d-sm-inline">Made in India</div> {/*end::To the end*/} {/*begin::Copyright*/} <strong>
                Copyright © 2024&nbsp;
                <a href="#" className="text-decoration-none">Quiz Admin</a>.
              </strong>
              All rights reserved.
              {/*end::Copyright*/}
            </footer>
    )
}

export default Footer;