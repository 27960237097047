import React from 'react'
import { APP_NAME, APP_ENV } from '../globals';

const LockScreen = () => {
    return (
        <div className="lockscreen bg-body-secondary">
            <div className="lockscreen-wrapper">
                <div className="lockscreen-logo"> <a href="../index2.html"><b>{ APP_NAME }</b>{ APP_ENV }</a> </div>
                <div className="lockscreen-name">John Doe</div>
                <div className="lockscreen-item">
                <div className="lockscreen-image"> <img src="../../../dist/assets/img/user1-128x128.jpg" alt="User Image" /> </div>
                <form className="lockscreen-credentials">
                    <div className="input-group"> <input type="password" className="form-control shadow-none" placeholder="password" />
                    <div className="input-group-text border-0 bg-transparent px-1"> <button type="button" className="btn shadow-none"> <i className="bi bi-box-arrow-right text-body-secondary" /> </button> </div>
                    </div>
                </form>
                </div>
                <div className="help-block text-center">
                Enter your password to retrieve your session
                </div>
                <div className="text-center"> <a href="/login" className="text-decoration-none">Or sign in as a different user</a> </div>
                <div className="lockscreen-footer text-center">
                Copyright © 2024 &nbsp;
                <b><a href="#" className="link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover">Quiz Admin</a></b> <br />
                All rights reserved
                </div>
            </div>
        </div>
    )
}

export default LockScreen;
